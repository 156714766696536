import faker from 'faker';
import { addMinutes, roundToNearestMinutes } from 'date-fns';
import { choiceToBoolean, getClient, randomInt } from '../helpers';
import moment from 'moment';
import momentDurationFormatSetup from "moment-duration-format";
import { flattenDeep, uniq } from 'lodash';
import axios from 'axios';

momentDurationFormatSetup(moment);
faker.locale = "pl";

const skill = ['intermediate', 'beginner', 'advanced', 'undefined'];
const visibility = ['public', 'private'];

const instructorsCountProbability = [1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 3, 3, 4];
const durationEventProbability = [45, 45, 45, 45, 45, 45, 45, 60, 60, 60, 60, 60, 60, 16, 120]

class Event {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
  }

  static async getEvents({ farmId, env, owner }, params) {
    const ownerClient = getClient(env, { token: owner.token });
    const { data } = await ownerClient.get(`${env}api/v1/farms/${farmId}/events`, { params });
    return data;
  }

  static async getExampleData({ env, owner, farmId }, { instructors, clients, horses, eventTypes, places }, options) {
    try {
      if (instructors.length < 1) throw new Error('Brak instruktorów w obiekcie');
      if (eventTypes.length < 1) throw new Error('Brak typów w obiekcie');
      if (places.length < 1) throw new Error('Brak miejsc w obiekcie');

      // Instrcutors 
      const randomInstructorsCount = faker.helpers.randomize(instructorsCountProbability);
      const randomInstructors = [...Array(randomInstructorsCount).keys()].map(() => faker.helpers.randomize(instructors));
      const instructorsIds = uniq(randomInstructors.map(instructor => instructor.id))

      // Clients
      const randomClientCount = { min: 1, max: clients.length > 8 ? 8 : clients.length };
      const randomClients = [...Array(randomInt(randomClientCount.min, randomClientCount.max)).keys()].map(() => faker.helpers.randomize(clients));
      const clientsIds = choiceToBoolean(options?.participants) ? uniq(randomClients.map(client => client.id)) : [];

      // Horses
      const randomHorses = [...Array(randomInt(0, randomClientCount.max)).keys()].map(() => faker.helpers.randomize(horses))
      const horsesIds = choiceToBoolean(options?.withHorses) ? uniq(randomHorses.map(horse => horse.id)) : [];


      // Date
      const randomDuration = faker.helpers.randomize(durationEventProbability);
      const duration = options?.duration > 0 ? parseInt(options.duration, 10) : randomDuration;
      const startAfter = !options.isRandomTimeInterval ? new Date(options.timeInterval[0]) : new Date();
      const startBefore = !options.isRandomTimeInterval ? new Date(options.timeInterval[1]) : faker.date.soon(30);
      const startAt = roundToNearestMinutes(faker.date.between(startAfter, startBefore), { nearestTo: 15 });
      const endAt = addMinutes(startAt, duration);

      // Others 
      const randomType = faker.helpers.randomize(eventTypes);
      const randomPlace = faker.helpers.randomize(places);
      const difficulty = faker.helpers.randomize(skill);

      // Name
      const durationString = moment.duration({ minutes: duration }).format("h[h] mm[m]")
      const name = `${randomType.name} / ${durationString} / ${randomPlace.name}`;

      // Excluded busy horses ids
      const { events: eventsOnThisTime } = await this.getEvents({ farmId, owner, env }, {
        per_page: 999,
        page: 1,
        'in_interval[start]': startAt,
        'in_interval[end]': endAt,
      });
      const busyHorsesIds = flattenDeep((eventsOnThisTime || []).map(({ horses }) => horses.map(({ id }) => id)));
      const event = {
        name,
        description: faker.lorem.sentence(),
        start_at: startAt,
        end_at: endAt,
        difficulty,
        special: faker.helpers.randomize(['false', 'true']),
        attendees_min: 1,
        attendees_max: randomClientCount.max + randomInt(0, 10),
        event_type_id: randomType.id,
        instructor_ids: instructorsIds,
        place_ids: [randomPlace.id],
        horse_ids: horsesIds.filter(horseId => !(busyHorsesIds || []).includes(horseId)),
        added_participant_ids: clientsIds,
        participant_ids: [],
        visibility: faker.helpers.randomize(visibility),
      };

      return { event, randomItems: { type: randomType } };
    } catch (e) {
      throw e;
    }
  }

  static async createProposal({ env, owner, farmId }, { instructors, clients, horses, eventTypes, places }, options) {
    try {
      const { event } = await this.getExampleData({ env, owner, farmId }, { instructors, clients, horses, eventTypes, places }, options);
      const publisher = faker.helpers.shuffle(clients).find(client => (client.identity.email || '').indexOf("@horsemanago.com") >= 0);

      if (!publisher?.identity?.email) {
        throw new Error('Brak danych klienta do zalogowania w obiekcie');
      }

      const loginResult = await axios.post(`${env}farms/${farmId}/login`, {
        user: {
          email: publisher?.identity?.email,
          password: 'string',
        },
      });
      const publisherClient = getClient(env, { token: loginResult.headers.authorization });
      const { data } = await publisherClient.post(`api/v1/farms/${farmId}/events/proposals`, {
        event: {
          ...event,
          participant_ids: event.added_participant_ids,
        }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async create({ env, owner, farmId }, { instructors, clients, horses, eventTypes, places }, options) {
    try {
      const ownerClient = getClient(env, { token: owner.token });
      const { event, randomItems } = await this.getExampleData({ env, owner, farmId }, { instructors, clients, horses, eventTypes, places }, options);
      const { data: dataEvent } = await ownerClient.post(`api/v1/farms/${farmId}/events`, { event });

      // Payments
      if (options?.payments !== '-1') {
        const joinedParticipations = dataEvent.participations.filter(participation => participation.status === 'joined');
        const eventCost = randomItems?.type?.default_event_cost || 0;
        const participation = {
          payment_status: "cash",
          paid_at: new Date(),
          paid_amount: parseInt(eventCost, 10),
        };

        let participationItem;
        for (participationItem of joinedParticipations) {
          if (choiceToBoolean(options?.payments)) {
            await ownerClient.patch(`api/v1/farms/${farmId}/events/${dataEvent.id}/participations/${participationItem.id}/set_payment`, { participation });
          }
        }
      }
      return dataEvent;
    } catch (e) {
      throw e;
    }
  };
}

export default Event;
