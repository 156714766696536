import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from '@chakra-ui/core';
import { AppContext, LogsContext } from '../contexts';
import Member from '../models/Members';
import Farm from '../models/Farms';

const Users = () => {
  const [appState] = useContext(AppContext);
  const [_, setLogs] = useContext(LogsContext);
  const [status, setStatus] = useState('1');
  const [phoneVerify, setPhoneVerify] = useState('1');
  const [role, setRole] = useState('1');
  const [count, setCount] = useState('1');
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [rolesApi, setRolesApi] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      setIsLoading(true);
      try {
        const result = await Farm.fetchRoles(appState);
        setRolesApi(result);
      } catch (e) {
        setRolesApi([]);
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchRoles();
  }, [appState]);

  const onAddMembership = async () => {
    setIsLoading(true);
    try {
      const repeatCountArray = [...Array(parseInt(count, 10)).keys()];
      for (const _ of repeatCountArray) {
        const user = await Member.createMembership(appState, {
          status,
          phoneVerify,
          role,
        });
        setLogs({
          type: 'addLog',
          payload: {
            id: user.membership.id,
            status: 'success',
            action: 'create',
            model: 'membership',
            name: `${user.firstname} ${user.lastname}`,
          },
        });
      }
      toast({
        title: 'Sukces!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      setLogs({
        type: 'addLog',
        payload: { status: 'error', model: 'membership', details: e?.message },
      });
      toast({
        description: e?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxW='fulll' p={6} overflow='auto'>
      <Stack spacing={6}>
        <Heading size='md'>Członkostwa</Heading>
        <Stack spacing={6} isInline>
          <Box bgColor='gray.100' px={4} py={4} shadow='md' flex='1'>
            <Stack spacing={4}>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Status</FormLabel>
                <RadioGroup value={status} onChange={setStatus}>
                  <Stack>
                    <Radio colorScheme='green' value='1'>
                      tylko z statusem 'active'
                    </Radio>
                    <Radio colorScheme='green' value='-1'>
                      tylko z statusem 'pending'
                    </Radio>
                    <Radio colorScheme='green' value='0'>
                      losowo
                    </Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Numer telefonu</FormLabel>
                <RadioGroup value={phoneVerify} onChange={setPhoneVerify}>
                  <Stack>
                    <Radio colorScheme='green' value='1'>
                      zweryfikowany
                    </Radio>
                    <Radio colorScheme='green' value='-1'>
                      niezweryfikowany
                    </Radio>
                    <Radio colorScheme='green' value='0'>
                      losowo
                    </Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Rola</FormLabel>
                <RadioGroup value={role} onChange={setRole}>
                  <Stack>
                    {rolesApi?.map((role) => {
                      return (
                        <Radio colorScheme='green' value={role.id.toString()}>
                          {role.abilities[0]}
                        </Radio>
                      );
                    })}
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Ilość</FormLabel>
                <NumberInput
                  defaultValue='1'
                  min='1'
                  value={count}
                  onChange={setCount}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <Flex>
                <Button
                  ml='auto'
                  isLoading={isLoading}
                  colorScheme='teal'
                  onClick={onAddMembership}
                >
                  Dodaj
                </Button>
              </Flex>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Users;
