import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useToast,
} from '@chakra-ui/core';
import Select from 'react-select';
import environmentsData from '../data/environments.json';
import { getClient } from '../helpers';
import { AppContext } from '../contexts';

const LoginPage = ({ onLogin, isLogin }) => {
  const [_, setAppState] = useContext(AppContext);
  const mapDataToOptions = (data) => {
    return Object.keys(data).map(environmentKey => ({ ...environmentsData[environmentKey] }));
  };

  const toast = useToast()
  const [options, setOptions] = useState(mapDataToOptions(environmentsData));
  const [selectedEnv, setSelectedEnv] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  const addOption = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    setSelectedEnv(newOption)
    setOptions(prevOptions => [...prevOptions, newOption]);
  }

  const onConnect = async () => {
    const ownerClient = getClient(`${selectedEnv?.value}api/v1/`);
    setAppState({ type: 'setEnv', payload: selectedEnv.value });
    setIsConnecting(true)
    try {
      await ownerClient.get('info/version');
      setIsConnected(true);
    } catch (e) {
      toast({
        description: e?.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    } finally {
      setIsConnecting(false);
    }

  }
  const NoOptionsMessage = ({ innerRef, selectProps }) => (
    <Box as="button" w="full" ref={innerRef} textAlign="center" onClick={() => {
      addOption(selectProps.inputValue);
      selectProps.onMenuClose();
    }} py={2}>
      Add evnironment
    </Box>
  );

  return (
    <Stack flexDirection="column" flex={1} spacing={4}>
      <FormControl>
        <FormLabel>Środowisko</FormLabel>
        <InputGroup>
          <Select
            isDisabled={isLogin}
            name="environments"
            components={{ NoOptionsMessage }}
            onChange={selectedOptions => setSelectedEnv(selectedOptions)}
            value={selectedEnv}
            options={options}
            styles={{
              container: styleProps => ({
                ...styleProps,
                flex: 1,
              })
            }}
          />
          <InputRightElement w="120px" mr={0} pr={0}>
            <Button ml="auto" onClick={onConnect} colorScheme="teal" isLoading={isConnecting} isDisabled={isLogin}>Połącz</Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl isDisabled={!isConnected || isLogin}>
        <FormLabel>Adres e-mail</FormLabel>
        <Input
          placeholder="wpisz adres e-mail"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
        />
      </FormControl>
      <FormControl isDisabled={!isConnected || isLogin}>
        <FormLabel>Hasło</FormLabel>
        <Input
          type="password"
          placeholder="wpisz hasło"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
        />
      </FormControl>
      <Flex>
        <Button
          type="submit"
          onClick={() => onLogin({ email, password, env: selectedEnv.value })}
          isDisabled={!isConnected}
          isLoading={isLogin}
          ml="auto"
          colorScheme="teal"
        >
          Zaloguj
        </Button>
      </Flex>
    </Stack>
  );
}

export default LoginPage;

