import React, { useReducer, useState, useEffect } from 'react';
import { Button, Flex, Heading, Link, List, ListIcon, ListItem, Stack, Text, useToast } from '@chakra-ui/core';
import { ChevronRightIcon, CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";
import { motion } from "framer-motion";
import Select from 'react-select';
import LoginPage from './pages/Login';
import { getClient } from './helpers';
import Sidebar from './components/Sidebar';
import Member from './models/Members';
import Users from './pages/Users';
import Events from './pages/Events';
import Horses from './pages/Horses';
import Types from './pages/Types';
import Places from './pages/Places';
import Ratings from './pages/Ratings';
import { appReducer, defaultAppState, initDrawer, initLogs, logsReducer } from './reducers';
import { AppContext, LogsContext } from './contexts';
import environmentsData from './data/environments.json';
import './App.css';

const MotionFlex = motion.custom(Flex);

const appStateFromLocalStorage = () => {
  return ({
    env: localStorage.getItem('env') ? JSON.parse(localStorage.getItem('env')) : defaultAppState.env,
    farmId: localStorage.getItem('farmId') ? JSON.parse(localStorage.getItem('farmId')) : defaultAppState.farmId,
    farms: (localStorage.getItem('farms') && localStorage.getItem('farms') !== 'undefined') ? JSON.parse(localStorage.getItem('farms')) : defaultAppState.farms,
    owner: localStorage.getItem('owner') ? JSON.parse(localStorage.getItem('owner')) : defaultAppState.owner,
  })
};

const logsStateFromLocalStorage = () => {
  return localStorage.getItem('logs') ? JSON.parse(localStorage.getItem('logs')) : [];
};

const variants = {
  notLogged: { width: '45rem', height: '23rem', borderRadius: '0.5rem', padding: '2rem', marginLeft: '0' },
  logged: { width: '100%', height: '100vh', borderRadius: '0', padding: '0', marginLeft: '200px' },
};

const App = () => {
  const [appState, setAppState] = useReducer(appReducer, appStateFromLocalStorage(), initDrawer);
  const [logsState, setLogs] = useReducer(logsReducer, logsStateFromLocalStorage(), initLogs);
  const [isLogin, setIsLogin] = useState(false);
  const isLogged = appState?.owner?.token;
  const toast = useToast()

  const onLogin = async ({ email, password, env }) => {
    try {
      setIsLogin(true);
      const ownerClient = getClient(env);
      const { data, headers } = await ownerClient.post('login', { user: { email, password } });
      const owner = {
        id: data.id,
        email: data.email,
        firstName: data.firstname,
        lastName: data.lastname,
        token: headers.authorization,
      }

      if (!data.phone_verified) {
        throw new Error('User does not have a verified number');
      }

      setAppState({ type: 'setOwner', payload: owner });
    } catch (e) {
      toast({
        description: e?.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setIsLogin(false);
  };

  useEffect(() => {
    const getFarms = async () => {
      console.log('test');
      try {
        const member = new Member(appState.owner);
        const ownerClient = getClient(appState?.env, { token: appState?.owner?.token });
        const farms = await member.getOwnFarms(ownerClient);
        if (farms.length > 0) {
          setAppState({ type: 'setFarm', payload: farms[0].id });
          setAppState({ type: 'setFarms', payload: farms });
        }
      } catch (e) {
        logout();
      }
    };

    if (appState?.farms?.length === 0 && appState?.owner?.token) {
      getFarms();
    }
  }, [appState.owner, appState.env, appState.farms])

  const logout = () => {
    setAppState({ type: 'clearFarm' });
    setAppState({ type: 'clearFarms' });
    setAppState({ type: 'clearOwner' });
    setLogs({ type: 'clearLogs' });
  }

  const selectedFarm = (appState?.farms || []).find(farm => farm.id === appState?.farmId);
  const defaultFarmOption = { value: selectedFarm?.id, label: selectedFarm?.name };

  return (
    <AppContext.Provider value={[appState, setAppState]}>
      <LogsContext.Provider value={[logsState, setLogs]}>
        <Router>
          <Flex background="teal.700" h="100vh" flexDir="column">
            <Flex w="full" position="fixed" h="100vh" justifyContent="center" alignItems="center">
              <MotionFlex
                background="white"
                variants={variants}
                animate={isLogged ? 'logged' : 'notLogged'}
                transition={{ duration: 0.5 }}
                initial={isLogged ? 'logged' : 'notLogged'}
                style={{ overflow: 'hidden' }}
              >
                {!isLogged && (
                  <LoginPage onLogin={onLogin} isLogin={isLogin} />
                )}
                {isLogged && (
                  <Flex flex={1}>
                    <Sidebar>
                      <SidebarItem to="/">Członkostwa</SidebarItem>
                      <SidebarItem to="/events">Wydarzenia</SidebarItem>
                      <SidebarItem to="/horses">Konie</SidebarItem>
                      <SidebarItem to="/places">Miejsca</SidebarItem>
                      <SidebarItem to="/types">Typy</SidebarItem>
                      <SidebarItem to="/ratings">Oceny</SidebarItem>
                    </Sidebar>
                    <Flex flex={1} flexDir="column">
                      <Flex bgColor="gray.700" color="white" py={2} px={6} alignItems="center" borderBottom="1px solid" borderColor="gray.900">
                        <Stack alignItems="center" isInline>
                          <Text>Farma</Text>
                          {}
                          <Select
                            name="farms"
                            placeholder="wybierz farme"
                            defaultValue={defaultFarmOption}
                            value={defaultFarmOption}
                            onChange={selectedOptions => setAppState({ type: 'setFarm', payload: selectedOptions.value })}
                            options={(appState?.farms || []).map(farm => ({ value: farm.id, label: farm.name }))}
                            styles={{
                              option: styleProps => ({
                                ...styleProps,
                                color: 'black',
                                fontSize: '14px'
                              }),
                              container: styleProps => ({
                                ...styleProps,
                                width: '200px',
                              })
                            }}
                          />
                        </Stack>
                        <Button
                          colorScheme="teal"
                          onClick={logout}
                          size="sm"
                          ml="auto"
                        >
                          Wyloguj
                        </Button>
                      </Flex>
                      <Flex h="calc(100vh - 54px)">
                        <Flex flex={1}>
                          <Switch>
                            <Route path="/" exact>
                              <Users />
                            </Route>
                            <Route path="/events">
                              <Events />
                            </Route>
                            <Route path="/horses">
                              <Horses />
                            </Route>
                            <Route path="/types">
                              <Types />
                            </Route>
                            <Route path="/places">
                              <Places />
                            </Route>
                            <Route path="/ratings">
                              <Ratings />
                            </Route>
                          </Switch>
                        </Flex>
                        <Flex w={400} bgColor="gray.700" color="white" p={6} overflow="auto" flexDir="column">
                          <Heading size="md" mb={6}>Dziennik aktywności</Heading>
                          {logsState.map((log, index) => {
                            const envData = Object.entries(environmentsData).find(([env, envData]) => envData.value === appState?.env);
                            const envUrl = envData[1]?.url;
                            return (
                              <List key={index}>
                                {log.status === 'success' && (
                                  <ListItem py={2} borderBottom="1px solid gray">
                                    <ListIcon as={CheckCircleIcon} color="green.500" />
                                    <Text as="span" fontWeight="bold">{getLogsByModel(log)}</Text><br />
                                    <Link isExternal href={getLinkByModel(log, envUrl, selectedFarm.slug)}>{log.name}</Link>
                                  </ListItem>
                                )}
                                {log.status === 'error' && (
                                  <ListItem py={2} borderBottom="1px solid gray">
                                    <ListIcon as={WarningIcon} color="red.500" />
                                      Bład: {log.model}<br />{log.details}
                                  </ListItem>
                                )}
                              </List>
                            );
                          })}
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </MotionFlex>
            </Flex>
          </Flex>
        </Router>
      </LogsContext.Provider>
    </AppContext.Provider>
  );
}

export default App;


const SidebarItem = ({ to, onClick, children, ...styleProps }) => {
  if (to) {
    return (
      <Link
        as={RouterLink}
        p={2}
        to={to}
        fontSize="sm"
        _hover={{
          textDecoration: 'none', bgColor: 'teal.900'
        }}
        d="flex"
        alignItems="center"
        {...styleProps}
      >
        <ChevronRightIcon mr={2} /> {children}
      </Link>
    );
  }
  return (
    <Flex
      p={2}
      onClick={onClick}
      fontSize="sm"
      _hover={{
        bgColor: 'teal.900'
      }}
      alignItems="center"
      {...styleProps}
    >
      <ChevronRightIcon mr={2} /> {children}
    </Flex>
  );
};

const getLogsByModel = (item) => {
  console.log(item);
  if (item.action === 'create') {
    return ({
      'horse': `Dodano konia #${item.id}:`,
      'event': `Dodano wydarzenie #${item.id}:`,
      'proposal': `Dodano propozycje #${item.id}:`,
      'place': `Dodano miejsce #${item.id}:`,
      'membership': `Dodano członkostwo #${item.id}:`,
      'type': `Dodano typ #${item.id}:`,
      'rating': `Dodano ocene do elementu #${item.id}:`,
    }[item.model]);
  }
  if (item.action === 'createRate') {
    return ({
      'horse': `Dodano ocenę do konia #${item.id}:`,
      'membership': `Dodano ocenę do instruktora #${item.id}:`,
      'farm': `Dodano ocenę do farmy #${item.id}:`,
    }[item.model]);
  }
}

const getLinkByModel = (item, envUrl, farmSlug) => {
  if (item.action === 'create') {
    return ({
      'horse': `${envUrl}${farmSlug}/horses/${item.id}`,
      'event': `${envUrl}${farmSlug}/events/${item.id}`,
      'proposal': `${envUrl}${farmSlug}/proposals/${item.id}`,
      'place': `${envUrl}${farmSlug}/places/${item.id}`,
      'membership': `${envUrl}${farmSlug}/users/${item.id}`,
      'type': `${envUrl}${farmSlug}/event-types/${item.id}`,
    }[item.model]);
  }
  if (item.action === 'createRate') {
    return ({
      'horse': `${envUrl}${farmSlug}/horses/${item.id}/rating`,
      'membership': `${envUrl}${farmSlug}/members/${item.id}/rating`,
      'farm': `${envUrl}${farmSlug}/rating`,
    }[item.model]);
  }
}