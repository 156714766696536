import { getClient, getSlug, getUniqueName } from '../helpers';
import placesData from '../data/places.json';

class Place {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
  }

  static async getPlaces({ farmId, env, owner }, params) {
    const ownerClient = getClient(env, { token: owner.token });
    const { data } = await ownerClient.get(`${env}api/v1/farms/${farmId}/places`, { params });
    return data;
  }

  static async create({ farmId, env, owner }) {
    const ownerClient = getClient(env, { token: owner.token });

    const { places } = await this.getPlaces({ farmId, env, owner }, { per_page: 999, page: 1 });
    const allPlacesNames = places.map(place => place.name);
    const name = getUniqueName(allPlacesNames, placesData['places']);
    const place = {
      name,
      slug: getSlug(name),
    }


    const { data } = await ownerClient.post(`${env}api/v1/farms/${farmId}/places`, { place });
    return data;

  }
}

export default Place;
