import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  useToast,
} from '@chakra-ui/core';
import { AppContext, LogsContext } from '../contexts';
import Places from '../models/Places';
import Member from '../models/Members';
import Horse from '../models/Horses';
import Select from 'react-select';
import Farm from '../models/Farms';

const RatingsPage = () => {
  const [appState] = useContext(AppContext);
  const [_, setLogs] = useContext(LogsContext);
  const [instructorsOptions, setInstructorsOptions] = useState([]);
  const [horsesOptions, setHorsesOptions] = useState([]);
  const [instructor, setInstructor] = useState(null);
  const [horse, setHorse] = useState(null);
  const [counts, setCounts] = useState(1);
  const [countHorse, setCountHorse] = useState(1);
  const [countFarm, setCountFarm] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast()

  useEffect(() => {
    const getOptions = async () => {
      setIsLoading(true);

      try {
        const { memberships: instructors } = await Member.getMembers({ ...appState }, { per_page: 999, with_role: 'instructor', with_status: ['active'] });
        const { horses } = await Horse.getHorses(appState, { per_page: 9999 });
        setInstructorsOptions(instructors.map(instructor => ({ value: instructor.id, label: `${instructor.identity.firstname} ${instructor.identity.lastname}` })));
        setHorsesOptions(horses.map(horse => ({ value: horse.id, label: horse.name })));
      } catch (e) {
        toast({
          description: e?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
    getOptions();
  }, [appState]);

  const onAddRating = async (modelName) => {
    setIsLoading(true);
    try {
      const { memberships: clients } = await Member.getMembers({ ...appState }, { per_page: 999, with_role: 'client', with_status: ['active'] });
      const publishers = clients.filter(client => client.identity.phone_verified);
      if (modelName === 'horse') {
        await Horse.createRating(appState, { horseId: horse.value, clients: publishers, counts: countHorse });
        setLogs({ type: 'addLog', payload: { id: horse.value, status: 'success', action: 'createRate', model: 'horse', name: horse.label } });
      }
      if (modelName === 'member') {
        await Member.createRating(appState, { memberId: instructor.value, clients: publishers, counts });
        setLogs({ type: 'addLog', payload: { id: instructor.value, status: 'success', action: 'createRate', model: 'membership', name: instructor.label } });
      }
      if (modelName === 'farm') {
        const farmData = appState.farms.find(farm => farm.id === appState.farmId);
        await Farm.createRating(appState, { clients: publishers, counts: countFarm });
        setLogs({ type: 'addLog', payload: { id: farmData.id, status: 'success', action: 'createRate', model: 'farm', name: farmData.name } });
      }
      toast({
        title: 'Sukces!',
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      setLogs({ type: 'addLog', payload: { status: 'error', model: 'place', details: e?.message } });
      toast({
        description: e?.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxW="fulll" p={6} overflow="auto">
      <Stack spacing={6}>
        <Heading size="md">Oceny</Heading>
        <Stack spacing={6}>
          <Box bgColor="gray.100" px={4} py={4} shadow="md" flex="1">
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Pracownicy</FormLabel>
                <Select
                  name="instructor"
                  placeholder="wybierz instruktora"
                  value={instructor}
                  onChange={setInstructor}
                  options={instructorsOptions}
                />
              </FormControl>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Ilość ocen</FormLabel>
                <NumberInput defaultValue="1" min="1" value={counts} onChange={setCounts}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <Flex>
                <Button ml="auto" isLoading={isLoading} isDisabled={!instructor} colorScheme="teal" onClick={() => onAddRating('member')}>Dodaj</Button>
              </Flex>
            </Stack>
          </Box>
          <Box bgColor="gray.100" px={4} py={4} shadow="md" flex="1">
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Konie</FormLabel>
                <Select
                  name="horse"
                  placeholder="wybierz konia"
                  value={horse}
                  onChange={setHorse}
                  options={horsesOptions}
                />
              </FormControl>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Ilość ocen</FormLabel>
                <NumberInput defaultValue="1" min="1" value={countHorse} onChange={setCountHorse}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <Flex>
                <Button ml="auto" isLoading={isLoading} isDisabled={!horse} colorScheme="teal" onClick={() => onAddRating('horse')}>Dodaj</Button>
              </Flex>
            </Stack>
          </Box>
          <Box bgColor="gray.100" px={4} py={4} shadow="md" flex="1">
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Farma</FormLabel>
              </FormControl>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Ilość ocen</FormLabel>
                <NumberInput defaultValue="1" min="1" value={countFarm} onChange={setCountFarm}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <Flex>
                <Button ml="auto" isLoading={isLoading} colorScheme="teal" onClick={() => onAddRating('farm')}>Dodaj</Button>
              </Flex>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Container >
  );
};

export default RatingsPage;
