import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from '@chakra-ui/core';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker'

import { AppContext, LogsContext } from '../contexts';
import Member from '../models/Members';
import Place from '../models/Places';
import Type from '../models/Types';
import Horse from '../models/Horses';
import Event from '../models/Events';

const Events = () => {
  const [appState] = useContext(AppContext);
  const [_, setLogs] = useContext(LogsContext);
  const [participants, setParticipants] = useState('1');
  const [withHorses, setWithHorses] = useState('1');
  const [payments, setPayments] = useState('1');
  const [duration, setDuration] = useState('0');
  const [count, setCount] = useState('1');
  const [isRandomTimeInterval, setIsRandomTimeInterval] = useState(true);
  const [isProposal, setIsProposal] = useState(false);
  const [timeInterval, setTimeInterval] = useState([new Date(), new Date()]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast()

  const onAddEvent = async () => {
    setIsLoading(true);
    const { memberships: instructors } = await Member.getMembers({ ...appState }, { per_page: 999, with_role: 'instructor', with_status: ['active'] });
    const { memberships: clients } = await Member.getMembers({ ...appState }, { per_page: 999, with_role: 'client', with_status: ['active'] });
    const { places } = await Place.getPlaces(appState, { per_page: 9999 });
    const { event_types: eventTypes } = await Type.getTypes({ ...appState }, { per_page: 9999 });
    const { horses } = await Horse.getHorses(appState, { per_page: 9999 });

    try {
      const repeatCountArray = [...Array(parseInt(count, 10)).keys()];
      for (const _ of repeatCountArray) {
        const eventData = await Event[isProposal ? 'createProposal' : 'create'](
          appState,
          { instructors, clients, places, eventTypes, horses },
          { participants, payments, duration, withHorses, isRandomTimeInterval, timeInterval },
        );
        setLogs({ type: 'addLog', payload: { id: eventData.id, status: 'success', action: 'create', model: isProposal ? 'proposal' : 'event', name: eventData.name } });
      }
      toast({
        title: 'Sukces!',
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        description: e?.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setLogs({ type: 'addLog', payload: { status: 'error', model: 'event', details: e?.message } });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxW="fulll" p={6} overflow="auto">
      <Stack spacing={6}>
        <Heading size="md">Wydarzenia</Heading>
        <Stack spacing={6} isInline>
          <Box bgColor="gray.100" px={4} py={4} shadow="md" flex="1">
            <Stack spacing={4}>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Uczestnicy</FormLabel>
                <RadioGroup value={participants} onChange={setParticipants}>
                  <Stack>
                    <Radio colorScheme="green" value="1" >tak</Radio>
                    <Radio colorScheme="green" value="-1">nie</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Konie</FormLabel>
                <RadioGroup value={withHorses} onChange={setWithHorses}>
                  <Stack>
                    <Radio colorScheme="green" value="1" >tak</Radio>
                    <Radio colorScheme="green" value="-1">nie</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Wydarzenie opłacone</FormLabel>
                <RadioGroup value={payments} onChange={setPayments}>
                  <Stack>
                    <Radio colorScheme="green" value="1">tak</Radio>
                    <Radio colorScheme="green" value="-1">nie</Radio>
                    <Radio colorScheme="green" value="0">częściowo</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Przedział czasowy</FormLabel>
                <Stack>
                  <Checkbox
                    colorScheme="green"
                    isChecked={isRandomTimeInterval}
                    onChange={e => setIsRandomTimeInterval(e.target.checked)}
                  >
                    Losowy
                  </Checkbox>
                  <Flex>
                    <DateTimeRangePicker
                      disabled={isRandomTimeInterval}
                      locale="pl-PL"
                      onChange={setTimeInterval}
                      value={timeInterval}
                    />
                  </Flex>
                </Stack>
              </FormControl>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Czas trwania</FormLabel>
                <NumberInput defaultValue="0" min="0" value={duration} onChange={setDuration}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText>Wpisanie wartości 0 ustawia czas trwania losowo</FormHelperText>
              </FormControl>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Ilość</FormLabel>
                <NumberInput defaultValue="1" min="1" value={count} onChange={setCount}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Propozycja</FormLabel>
                <Checkbox
                  colorScheme="green"
                  isChecked={isProposal}
                  onChange={e => setIsProposal(e.target.checked)}
                >
                  tak
                </Checkbox>
              </FormControl>
              <Flex>
                <Button ml="auto" isLoading={isLoading} colorScheme="teal" onClick={onAddEvent}>Dodaj</Button>
              </Flex>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Events;
