import { format, subYears } from 'date-fns';
import faker from 'faker';
import { getClient, getUniqueName, randomInt } from '../helpers';
import breedsData from '../data/breeds.json';
import horsesData from '../data/horses.json';
import Type from './Types';
import { uniq } from 'lodash';
import axios from 'axios';

const skills = ['intermediate', 'beginner', 'advanced', 'undefined'];

class Horse {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
  }

  static async getHorses({ farmId, env, owner }, params) {
    const ownerClient = getClient(env, { token: owner.token });
    const { data } = await ownerClient.get(`${env}api/v1/farms/${farmId}/horses`, { params });
    return data;
  }

  static async create({ farmId, env, owner }) {
    const ownerClient = getClient(env, { token: owner?.token });

    /* Resources */
    const { horses } = await this.getHorses({ env, owner, farmId }, { per_page: 9999, page: 1 });
    const { event_types: eventTypes } = await Type.getTypes({ env, owner, farmId }, { per_page: 9999, page: 1 });
    const existHorseNames = horses.map(horse => horse.name);
    const randomEventType = [...Array(randomInt(1, 4)).keys()].map(() => faker.helpers.randomize(eventTypes));

    /* Horse data */
    const horse = {
      name: getUniqueName(existHorseNames, horsesData['horses']),
      birthday: format(subYears(faker.date.past(20), 1), 'yyyy-MM-dd'),
      breed: faker.helpers.randomize(breedsData['breeds']),
      description: faker.lorem.sentences(),
      difficulty: faker.helpers.randomize(skills),
      event_type_ids: uniq(randomEventType.map(eventType => eventType.id)),
      health_status: faker.lorem.word(),
      range: randomInt(3, 24),
    }
    const { data } = await ownerClient.post(`${env}api/v1/farms/${farmId}/horses`, { horse });
    return data;
  }

  static async createRating({ farmId, env, owner }, { horseId, clients, counts }) {
    const publishers = faker.helpers.shuffle(clients).filter(client => (client.identity.email || '').indexOf("@horsemanago.com") >= 0).slice(0, counts);
    for (const publisher of publishers) {
      if (!publisher?.identity?.email) {
        throw new Error('Brak danych klienta do zalogowania w obiekcie');
      }
      const loginResult = await axios.post(`${env}farms/${farmId}/login`, {
        user: {
          email: publisher?.identity?.email,
          password: 'string',
        },
      });
      const publisherClient = getClient(env, { token: loginResult.headers.authorization });
      await publisherClient.post(`api/v1/farms/${farmId}/horses/${horseId}/rate`, { rate: { stars: randomInt(1, 5), comment: faker.lorem.sentences() } });
    }
  }
}

export default Horse;
