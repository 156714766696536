import axios from 'axios';
import faker from 'faker';
import { compact } from 'lodash';

export const getClient = (env, options) => {
  const client = axios.create({
    baseURL: env,
    timeout: 20000,
    ...(options?.token ? {
      headers: {
        common: {
          Authorization: options.token,
        }
      }
    } : {})
  });
  return client;
};

export const randomInt = (min, max) => {
  return min + Math.floor((max - min) * Math.random());
}

export const choiceToBoolean = (value) => {
  // 0 random | -1 false | 1 true
  if (value === '0' && faker.helpers.randomize([true, false])) {
    return true;
  }
  if (value === '1') {
    return true;
  }
  return false;
}

export const getUniqueName = (allNames, datas) => {
  const name = faker.helpers.randomize(datas);
  if (datas.length === 0) {
    return faker.lorem.word(12).toUpperCase();
  }

  if (allNames.includes(name)) {
    return getUniqueName(allNames, datas.filter(itemName => itemName !== name));
  }
  return name;
};

export const getSlug = (value) => {
  let newSlug = '';
  if (value.indexOf(' ') !== -1) {
    const eventTypeNameCharsArray = compact(value.split(' '));
    newSlug = `${eventTypeNameCharsArray[0][0]}${eventTypeNameCharsArray[1][0]}`;
  } else {
    newSlug = value ? value.slice(0, 2) : '';
  }
  return newSlug;
}