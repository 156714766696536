import faker from 'faker';

import { getClient, getSlug, getUniqueName } from '../helpers';
import typesData from '../data/types.json';
import Place from './Places';

class Type {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
  }

  static async getTypes({ farmId, env, owner }, params) {
    const ownerClient = getClient(env, { token: owner.token });
    const { data } = await ownerClient.get(`${env}api/v1/farms/${farmId}/event_types`, { params });
    return data;
  }

  static async create({ farmId, env, owner }) {
    const ownerClient = getClient(env, { token: owner.token });

    const { event_types: eventTypes } = await this.getTypes({ env, owner, farmId }, { per_page: 9999, page: 1 });
    const { places } = await Place.getPlaces({ env, owner, farmId }, { per_page: 9999, page: 1 });

    const eventTypesNames = eventTypes.map(eventType => eventType.name);
    const name = getUniqueName(eventTypesNames, typesData['types']);

    const event_type = {
      name,
      default_event_cost: faker.helpers.randomize([50, 50, 50, 40, 40, 20, 80.100]),
      place_id: faker.helpers.randomize(places),
      slug: getSlug(name),
      time_intervals: [faker.helpers.randomize([30, 30, 45, 45, 45, 90, 90, 90, 120])],
    }

    const { data } = await ownerClient.post(`${env}api/v1/farms/${farmId}/event_types`, { event_type });
    return data;
  }
}

export default Type;
