import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  useToast,
} from '@chakra-ui/core';
import { AppContext, LogsContext } from '../contexts';
import Places from '../models/Places';

const PlacePage = () => {
  const [appState] = useContext(AppContext);
  const [_, setLogs] = useContext(LogsContext);
  const [count, setCount] = useState('1');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast()

  const onAddPlace = async () => {
    setIsLoading(true);
    try {
      const repeatCountArray = [...Array(parseInt(count, 10)).keys()];
      for (const _ of repeatCountArray) {
        const place = await Places.create(appState);
        setLogs({ type: 'addLog', payload: { id: place.id, status: 'success', action: 'create', model: 'place', name: place.name }});
      }
      toast({
        title: 'Sukces!',
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      setLogs({ type: 'addLog', payload: { status: 'error', model: 'place', details: e?.message }});
      toast({
        description: e?.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxW="fulll" p={6} overflow="auto">
      <Stack spacing={6}>
        <Heading size="md">Miejsca</Heading>
        <Stack spacing={6} isInline>
          <Box bgColor="gray.100" px={4} py={4} shadow="md" flex="1">
            <Stack spacing={4}>
              <FormControl isDisabled={isLoading}>
                <FormLabel>Ilość</FormLabel>
                <NumberInput defaultValue="1" min="1" value={count} onChange={setCount}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <Flex>
                <Button ml="auto" isLoading={isLoading} colorScheme="teal" onClick={onAddPlace}>Dodaj</Button>
              </Flex>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default PlacePage;
