import axios from 'axios';
import faker from 'faker';
import { format, subYears } from 'date-fns';
import latinize from 'latinize';
import { choiceToBoolean, getClient, randomInt } from '../helpers';

faker.locale = 'pl';
const skill = ['intermediate', 'beginner', 'advanced', 'undefined'];

class Member {
  constructor(data) {
    this.id = data.id;
    this.membershipId = data.membershipId;
    this.fistName = data.firstName;
    this.lastName = data.lastName;
    this.avatar = data.avatar;
    this.token = data.token;
  }

  static async createMembership({ env, owner, farmId }, options) {
    try {
      const firstname = faker.name.firstName();
      const lastname = faker.name.lastName();
      const password = 'string';
      const birthday = format(subYears(faker.date.past(20), 18), 'yyyy-MM-dd');
      const phone = faker.phone.phoneNumber('+48#########');
      const email = `${latinize(firstname.toLowerCase())}.${latinize(
        lastname.toLowerCase()
      )}@horsemanago.com`;

      const user = {
        firstname,
        lastname,
        password,
        birthday,
        email,
        phone,
        terms_accepted: true,
      };

      const membership = {
        skill: skill[firstname.length % 4],
        additional_info: [faker.lorem.sentence(), ''][lastname.length % 2],
        // 3 client | 2 instructor
        role_id: options?.role,
      };

      await axios.post(`${env}/signup`, { user });
      const loginResult = await axios.post(`${env}farms/${farmId}/login`, {
        user: { email: user.email, password },
      });
      const memberClient = getClient(env, {
        token: loginResult.headers.authorization,
      });
      const ownerClient = getClient(env, { token: owner.token });
      const clientMembership = loginResult?.data?.identity?.membership;

      if (choiceToBoolean(options?.phoneVerify)) {
        const sendSmsResult = await memberClient.post(`${env}login/send_sms`);
        await memberClient.post(
          `${env}login/verify_sms_code`,
          sendSmsResult.data
        );
      }
      if (choiceToBoolean(options?.status)) {
        await ownerClient.patch(
          `${env}api/v1/farms/${farmId}/memberships/${clientMembership.id}/accept`
        );
      }
      await ownerClient.patch(
        `${env}api/v1/farms/${farmId}/memberships/${clientMembership.id}`,
        { membership }
      );
      return loginResult?.data?.identity;
    } catch (e) {
      throw e;
    }
  }

  static async getMembers({ farmId, env, owner }, params) {
    const ownerClient = getClient(env, { token: owner.token });
    const { data } = await ownerClient.get(
      `${env}api/v1/farms/${farmId}/memberships`,
      { params }
    );
    return data;
  }

  async getOwnFarms(client) {
    try {
      const { data } = await client.get('api/v1/farms/mine');
      const farms = data?.farms || [];
      const ownProfiles = await Promise.all(
        farms.map((farm) => {
          const profileResponse = new Promise((resolve) => {
            client.get(`api/v1/farms/${farm.id}/profile`).then(({ data }) => {
              resolve({ ...data, farmId: farm.id });
            });
          });
          return profileResponse;
        })
      );

      const ownFarmsIds = ownProfiles
        .filter(
          (profileData) =>
            profileData?.membership?.role?.abilities[0] === 'owner'
        )
        .map((profile) => profile.farmId);
      return farms.filter((farm) => ownFarmsIds.includes(farm.id));
    } catch (e) {
      console.log(e);
    }
  }

  static async createRating(
    { farmId, env, owner },
    { memberId, clients, counts }
  ) {
    const publishers = faker.helpers
      .shuffle(clients)
      .filter(
        (client) =>
          (client.identity.email || '').indexOf('@horsemanago.com') >= 0
      )
      .slice(0, counts);
    for (const publisher of publishers) {
      if (!publisher?.identity?.email) {
        throw new Error('Brak danych klienta do zalogowania w obiekcie');
      }
      const loginResult = await axios.post(`${env}farms/${farmId}/login`, {
        user: {
          email: publisher?.identity?.email,
          password: 'string',
        },
      });
      const publisherClient = getClient(env, {
        token: loginResult.headers.authorization,
      });
      await publisherClient.post(
        `api/v1/farms/${farmId}/instructors/${memberId}/rate`,
        { rate: { stars: randomInt(1, 5), comment: faker.lorem.sentences() } }
      );
    }
  }
}

export default Member;
