export const defaultAppState = {
  env: '',
  owner: {
    id: null,
    email: '',
    firstName: '',
    lastName: '',
    avatar: '',
    token: '',
  },
  farmId: null,
  farms: [],
};

export const initDrawer = (appState) => {
  return ({
    env: appState?.env || defaultAppState.env,
    owner: appState?.owner || defaultAppState.owner,
    farmId: appState?.farmId || defaultAppState.farmId,
    farms: appState?.farms || defaultAppState.farms,
  });
};

export const appReducer = (state, action) => {
  const setNewValue = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
    return {
      ...state,
      [key]: value,
    };
  };

  switch (action.type) {
    case 'setEnv':
      return setNewValue('env', action.payload);
    case 'setOwner':
      return setNewValue('owner', { ...state.owner, ...action.payload });
    case 'clearOwner':
      return setNewValue('owner', {});
    case 'setFarms':
      return setNewValue('farms', action.payload);
    case 'setFarm':
      return setNewValue('farmId', action.payload);
    case 'clearFarm':
      return setNewValue('farm', null);
    case 'clearFarms':
      return setNewValue('farms', []);
    default:
      throw new Error('No action was found')
  }
};


export const initLogs = (appState) => {
  return appState || [];
};

export const logsReducer = (state, action) => {
  const setNewValue = (value) => {
    localStorage.setItem('logs', JSON.stringify([value, ...state,]));
    return [value, ...state];
  };

  switch (action.type) {
    case 'addLog':
      return setNewValue(action.payload);
    case 'clearLogs':
      return [];
    default:
      throw new Error('No action was found')
  }
};

