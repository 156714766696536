import faker from 'faker';
import { getClient, randomInt } from '../helpers';
import axios from 'axios';

class Farm {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
  }

  static async createRating({ farmId, env, owner }, { clients, counts }) {
    const publishers = faker.helpers
      .shuffle(clients)
      .filter(
        (client) =>
          (client.identity.email || '').indexOf('@horsemanago.com') >= 0
      )
      .slice(0, counts);
    for (const publisher of publishers) {
      if (!publisher?.identity?.email) {
        throw new Error('Brak danych klienta do zalogowania w obiekcie');
      }
      const loginResult = await axios.post(`${env}farms/${farmId}/login`, {
        user: {
          email: publisher?.identity?.email,
          password: 'string',
        },
      });
      const publisherClient = getClient(env, {
        token: loginResult.headers.authorization,
      });
      await publisherClient.post(`api/v1/farms/${farmId}/rate`, {
        rate: { stars: randomInt(1, 5), comment: faker.lorem.sentences() },
      });
    }
  }

  static async fetchRoles({ farmId, env, owner }) {
    const ownerClient = getClient(env, { token: owner.token });
    const response = await ownerClient.get(
      `${env}api/v1/farms/${farmId}/roles`
    );
    return response && response.data
      ? response.data.filter((roleItem) => roleItem.default)
      : [];
  }
}

export default Farm;
