import React from 'react';
import { Box, Flex, Image, Stack, Text } from '@chakra-ui/core';
import { motion } from "framer-motion";
import logo from '../hm-logo.svg';

const MotionBox = motion.custom(Box);
const Sidebar = ({ children }) => {
  return (
    <MotionBox
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      position="fixed"
      opacity={0}
      d="flex"
      flexDir="column"
      color="white"
      left={0}
      top={0}
      w="200px"
      h="100vh"
    >
      <Flex justifyContent="center" py={4} mt={4}>
        <Image src={logo} className="app-logo" alt="logo" w={24} />
      </Flex>
      <Text fontWeight="bold" w="full" textAlign="center" mb={8}>GENERATOR</Text>
      <Box bgColor="teal.800" flex={1}>
        <Stack spacing={0}>
          {children}
        </Stack>
      </Box>
    </MotionBox>
  );
};

export default Sidebar;